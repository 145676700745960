import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';
import styles from '../styles/HomeResultsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';

const HomeResultsPage = () => {
  const navigate = useNavigate();
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tipsPerPage = 10;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchResults();
  }, []);

  const fetchResults = async () => {
    try {
      console.log('Fetching from:', `${API_URL}/api/tips/public-results/`);
      const response = await fetch(`${API_URL}/api/tips/public-results/`);
      console.log('Response status:', response.status);
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error data:', errorData);
        throw new Error(`Failed to fetch results: ${errorData.detail || 'Unknown error'}`);
      }
      const data = await response.json();
      
      // Filter for last 30 days
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      const filteredData = data.filter(tip => {
        const tipDate = new Date(tip.date);
        return tipDate >= thirtyDaysAgo;
      });
      
      setTips(filteredData);
    } catch (err) {
      console.error('Error details:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const profits = calculateProfitLoss(tips);

  const calculateStats = (tips) => {
    // Filter for Solid Picks, NAPs, and Value Picks
    const premiumTips = tips.filter(tip => 
      tip.type === "Solid Pick" ||  // Solid Picks
      tip.bet_type === "nap" ||     // NAPs
      tip.type === "Value Pick"     // Value Picks
    );

    // Get premium winners (excluding NR)
    const premiumWinners = premiumTips.filter(tip => 
      tip.result === "WON" && 
      tip.result !== "NR"
    );

    // Calculate stats
    const stats = {
      totalTips: premiumTips.filter(tip => tip.result !== "NR").length, // Exclude NR from total
      winners: premiumWinners.length,
      winRate: 0,
      avgOdds: 0,
      bestWin: { odds: 0, horse: '', date: '', location: '' }
    };

    // Calculate win rate excluding NR
    const validPremiumTips = premiumTips.filter(tip => tip.result !== "NR").length;
    stats.winRate = validPremiumTips > 0 
      ? ((premiumWinners.length / validPremiumTips) * 100).toFixed(1) 
      : '0';

    // Debug logging
    console.log('Stats calculation:', {
      totalTipsBeforeNR: premiumTips.length,
      totalTipsAfterNR: validPremiumTips,
      premiumWinnersCount: premiumWinners.length,
      winRate: stats.winRate
    });
    
    // Calculate average odds of ALL winners (this stays the same)
    const allWinners = tips.filter(tip => tip.result === "WON");
    if (allWinners.length > 0) {
      const allWinningOdds = allWinners.map(tip => Number(tip.odds));
      stats.avgOdds = (allWinningOdds.reduce((a, b) => a + b, 0) / allWinners.length).toFixed(2);
    }
    
    // Find best winning odds from ALL winners
    const bestWin = allWinners.sort((a, b) => Number(b.odds) - Number(a.odds))[0];
    if (bestWin) {
      stats.bestWin = {
        odds: bestWin.odds,
        horse: bestWin.horse,
        date: bestWin.date,
        location: capitalizeFirstLetter(bestWin.location)
      };
    }

    return stats;
  };

  const stats = calculateStats(tips);

  if (loading) return <LoadingSpinner text="Loading results..." />;
  if (error) return <div className={styles.error}>Error: {error}</div>;

  const indexOfLastTip = currentPage * tipsPerPage;
  const indexOfFirstTip = indexOfLastTip - tipsPerPage;
  const currentTips = tips.slice(indexOfFirstTip, indexOfLastTip);
  const totalPages = Math.ceil(tips.length / tipsPerPage);

  return (
    <div className={styles.resultsContainer}>
      <h1 className={styles.mainTitle}>Our Track Record</h1>

      
      {/* Best Winner Highlight */}
      {stats.bestWin.odds > 0 && (
        <div className={styles.highlightCard}>
          <h3 className={styles.highlightTitle}>Biggest Winner This Month</h3>
          <div className={styles.winnerDetails}>
            <span className={styles.bigWinnerHorse}>{stats.bestWin.horse}</span>
            <span className={styles.bigWinnerLocation}>{stats.bestWin.location}</span>
            <div className={styles.winnerStats}>
              <span className={styles.bigWinnerOdds}>{stats.bestWin.odds}</span>
              <span className={styles.bigWinnerDate}>{stats.bestWin.date}</span>
            </div>
          </div>
        </div>
      )}
      
      {/* Key Statistics */}
      <div className={styles.statsGrid}>
        <div className={styles.statCard}>
          <h3>Win Rate</h3>
          <div className={styles.statValue}>
            {stats.winRate > 0 ? `${stats.winRate}%` : '0%'}
          </div>
          <p>Last 30 Days</p>
        </div>
        <div className={styles.statCard}>
          <h3>Winners Found</h3>
          <div className={styles.statValue}>{stats.winners}</div>
          <p>From {stats.totalTips || 0} Tips</p>
        </div>
        <div className={styles.statCard}>
          <h3>Average Winner Odds</h3>
          <div className={styles.statValue}>
            {stats.avgOdds > 0 ? stats.avgOdds : '0.00'}
          </div>
          <p>Consistent Value</p>
        </div>
        <div className={styles.statCard}>
          <h3>Profit (£10 Stakes)</h3>
          <div className={`${styles.statValue} ${profits.winOnlyProfit >= 0 ? styles.profit : styles.loss}`}>
            £{profits.winOnlyProfit ? profits.winOnlyProfit.toFixed(2) : '0.00'}
          </div>
          <p>Return On Investment</p>
        </div>
      </div>


      {/* Recent Results */}
      <div className={styles.recentResults}>
        <h3 className={styles.sectionTitle}>Recent Winners</h3>
        <div className={styles.winnersList}>
          {tips
            .filter(tip => tip.result === 'WON')
            .sort((a, b) => Number(b.odds) - Number(a.odds)) // Sort by highest odds
            .slice(0, 6) // Take top 6 highest odds winners
            .map(tip => (
              <div key={tip.id} className={styles.winnerCard}>
                <div className={styles.horseInfo}>
                  <span className={styles.horseName}>{tip.horse}</span>
                  <span className={styles.courseLocation}>{capitalizeFirstLetter(tip.location)}</span>
                </div>
                <span className={styles.winnerOdds}>{tip.odds}</span>
              </div>
            ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className={styles.ctaSection}>
        <h2>Join Our Winning Team</h2>
        <p>Get access to all our premium tips for just £5 per month</p>
        <button className={styles.ctaButton} onClick={() => navigate('/account')}>
          Subscribe Now
        </button>
      </div>
    </div>
  );
};

const getRowClass = (result) => {
  switch (result) {
    case 'WON':
      return styles.winner;
    case 'LOST':
      return styles.loser;
    case 'NR':
      return styles.nr;
    case 'PLACED':
      return styles.placed;
    default:
      return '';
  }
};

export default HomeResultsPage;