import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import styles from '../styles/VipPage.module.css';
import axios from 'axios';

const VipPage = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // Check admin status
        const user = JSON.parse(localStorage.getItem('user'));
        const isStaffMember = user?.is_staff || false;
        setIsAdmin(isStaffMember);

        // Only check subscription if not an admin
        if (!isStaffMember) {
          const response = await axios.get(`${API_URL}/api/subscription/check/`, {  // Changed from subscriptions to subscription
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          });
          setIsSubscribed(response.data.is_active);
        }
      } catch (error) {
        console.error('Error checking access:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [API_URL]);

  if (loading) return <LoadingSpinner text="Loading VIP area..." />;

  // Allow access if user is either an admin or has an active subscription
  if (!isAdmin && !isSubscribed) {
    return (
      <div className={styles.container}>
        <div className={styles.unauthorizedMessage}>
          <h2>VIP Access Required</h2>
          <p>You need an active subscription to access this area.</p>
          <button 
            onClick={() => navigate('/subscribe')}
            className={styles.subscribeButton}
          >
            Subscribe Now
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>VIP Dashboard</h1>
        {isAdmin && (
          <div className={styles.adminBadge}>
            Admin Access
          </div>
        )}
      </div>
      
      <div className={styles.content}>
        <nav className={styles.sidebar}>
          <NavLink 
            to="/vip/tips" 
            className={({ isActive }) => isActive ? styles.activeLink : styles.link}
          >
            Today's Tips
          </NavLink>
          <NavLink 
            to="/vip/results" 
            className={({ isActive }) => isActive ? styles.activeLink : styles.link}
          >
            Previous Results
          </NavLink>
          <NavLink 
            to="/vip/analytics" 
            className={({ isActive }) => isActive ? styles.activeLink : styles.link}
          >
            Profitability
          </NavLink>
        </nav>
        
        <main className={styles.main}>
          <Outlet context={{ isAdmin }} />
        </main>
      </div>
    </div>
  );
};

export default VipPage;