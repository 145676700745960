import { Link } from 'react-router-dom';
import styles from '../styles/LandingPage.module.css';
import { FaTrophy, FaChartLine, FaHeadset } from 'react-icons/fa'; // Add icons

const LandingPage = () => {
  return (
    <div className={styles.landingPage}>
      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <h1 className={styles.title}>Premium Horse Racing Tips</h1>
          <p className={styles.subtitle}>
            Join the elite community of successful bettors with our expert analysis and premium tips
          </p>
          <Link to="/register" className={styles.ctaButton}>
            Start Winning Today
          </Link>
        </div>
      </div>

      <div className={styles.subscriptionSticker}>
        <h2 className={styles.stickerTitle}>Special Offer: £5/month</h2>
        <p className={styles.stickerSubtitle}>Limited Time Only - Join Now and Transform Your Betting Game!</p>
      </div>

      <div className={styles.features}>
        <h2 className={styles.featuresTitle}>Why Choose MisterTipster?</h2>
        <div className={styles.featuresGrid}>
          <div className={styles.feature}>
            <FaTrophy size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Premium Tips</h3>
            <p>Carefully selected tips with proven track record of success and profitability</p>
          </div>
          <div className={styles.feature}>
            <FaChartLine size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Expert Analysis</h3>
            <p>In-depth analysis from industry professionals with years of experience</p>
          </div>
          <div className={styles.feature}>
            <FaHeadset size={40} color="#ffd700" />
            <h3 className={styles.featureTitle}>Dedicated Support</h3>
            <p>Round-the-clock support to help you make the most of your membership</p>
          </div>
        </div>
      </div>

      <div className={styles.registration}>
        <h2 className={styles.registrationTitle}>Ready to Start Winning?</h2>
        <p className={styles.registrationSubtitle}>
          Join thousands of successful members who trust our expert tips
        </p>
        <Link to="/register" className={styles.registrationButton}>
          Join MisterTipster Now
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;