import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasValidToken } from '../services/authService';
import styles from '../styles/FreeTipsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';

const FreeTipsPage = () => {
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedTips, setExpandedTips] = useState(new Set());
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (hasValidToken()) {
      fetchTips();
    } else {
      setLoading(false);
    }
  }, []);

  const fetchTips = async () => {
    try {
      const headers = {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.get(`${API_URL}/api/tips/`, { headers });
      
      // Filter for today's tips
      const today = new Date().toISOString().split('T')[0];
      const todaysTips = response.data.filter(tip => tip.date === today);
      
      setTips(todaysTips);
    } catch (err) {
      console.error('Error fetching tips:', err);
      if (err.response?.status === 401) {
        setError('Your session has expired. Please log in again.');
        localStorage.removeItem('access_token');
        navigate('/login');
      } else {
        setError(err.response?.data?.message || err.message || 'Failed to fetch tips');
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleTip = (tipId) => {
    setExpandedTips(prev => {
      const newSet = new Set(prev);
      if (newSet.has(tipId)) {
        newSet.delete(tipId);
      } else {
        newSet.add(tipId);
      }
      return newSet;
    });
  };

  const formatDate = (date) => {
    return new Date().toLocaleDateString('en-GB', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const capitalizeWords = (str) => {
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

  const TipCard = ({ tip }) => {
    const time = tip.race_time.split(' ')[1];
    const resultClass = tip.result ? styles[tip.result.toLowerCase()] : styles.pending;
    
    // Determine result text
    const resultText = tip.result || 'PENDING';
    
    return (
      <div className={`${styles.tipCard} ${resultClass}`}>
        <div className={styles.tipContent}>
          <span className={styles.horseName}>{tip.horse}</span>
          <div className={styles.raceDetails}>
            <span className={styles.location}>{capitalizeWords(tip.location)}</span>
            <span className={styles.time}>{time}</span>
          </div>
        </div>
        <div className={styles.tipInfo}>
          <span className={styles.odds}>{tip.odds}</span>
          <span className={styles.napBadge}>NAP</span>
        </div>
        <span className={styles.resultText}>{resultText}</span>
      </div>
    );
  };

  // Generate fake premium tips based on actual count
  const generatePremiumTeasers = () => {
    const premiumTypes = ['Solid Pick', 'Value Pick', 'Each Way Pick'];
    const tracks = ['Cheltenham', 'Ascot', 'Newmarket', 'Doncaster', 'Aintree'];
    const times = ['14:30', '15:15', '15:45', '16:20', '16:55'];
    
    // Filter out the NAP from total tips count
    const premiumTipsCount = tips.filter(tip => tip.type !== 'NAP').length;
    
    return Array(premiumTipsCount).fill(null).map((_, index) => ({
      id: `fake${index}`,
      horse: 'Premium Selection',
      location: tracks[index % tracks.length],
      race_time: times[index % times.length],
      type: premiumTypes[index % premiumTypes.length],
      odds: (2 + Math.random() * 8).toFixed(2),
      confidence: '8/15',
      reasons: 'Subscribe to view full analysis',
      racing_post_comment: 'Subscribe to view Racing Post comments'
    }));
  };

  const napTip = tips.find(tip => tip.type === 'NAP');
  const premiumTeasers = generatePremiumTeasers();

  if (loading) return <LoadingSpinner text="Loading tips..." />;
  if (error) return <div className={styles.error}>Error: {error}</div>;
  if (!hasValidToken()) {
    return (
      <div className={styles.container}>
        <div className={styles.unauthorizedMessage}>
          <h2>Free Daily NAP</h2>
          <p>Join our community of successful bettors and get daily access to our expert NAP selection completely free!</p>
          <button 
            onClick={() => navigate('/register')}
            className={styles.registerButton}
          >
            Register Now - It's Free!
          </button>
          <div className={styles.benefits}>
            <h3>Member Benefits</h3>
            <ul>
              <li>Daily NAP Selection</li>
              <li>Performance Tracking</li>
              <li>Instant Notifications</li>
              <li>Expert Analysis</li>
              <li>Betting Community</li>
              <li>Historical Results</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.dateHeader}>
        <h1>{formatDate(new Date())}</h1>
      </div>



      {napTip && (
        <div className={styles.napSection}>
          <h2>Free NAP of the Day</h2>
          <TipCard tip={napTip} />
        </div>
      )}

    <div className={styles.subscribePrompt}>
        <h2>Want More Winners?</h2>
        <p>Get access to all our premium tips for just £5 per month!</p>
        <button 
          onClick={() => navigate('/account')}
          className={styles.subscribeButton}
        >
          Subscribe Now
        </button>
      </div>
      
      <div className={styles.premiumSection}>
        <h2>Premium Tips (Subscribers Only)</h2>
        <p className={styles.tipCount}>
          {premiumTeasers.length} tips available today
        </p>
        <div className={`${styles.premiumTips} ${styles.blurredContent}`}>
          {premiumTeasers.map(tip => (
            <TipCard 
              key={tip.id} 
              tip={tip}
            />
          ))}
        </div>
        <div className={styles.premiumOverlay}>
          <button 
            onClick={() => navigate('/account')}
            className={styles.subscribeButton}
          >
            Subscribe to View All Tips
          </button>
        </div>
      </div>
    </div>
  );
};

export default FreeTipsPage;