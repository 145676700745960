import { loadStripe } from '@stripe/stripe-js';

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    // Try to get key from environment variables or fallback to hardcoded key for development
    const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51QJXtOGM5cr5wDp8Dy6gr9t4l93wqRTQ94rto68dyGo1Wx1LusdwhZh3wI7yovMKVT7HrmCdRzLOnRYjrJmtekhR006mf1q6Vn';
    
    console.log('Stripe initialization:', {
      hasKey: !!key,
      keyPrefix: key ? key.substring(0, 7) : 'not found',
      environment: process.env.NODE_ENV
    });

    if (!key) {
      throw new Error('Stripe publishable key is missing!');
    }

    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export default getStripe;