import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const checkSubscription = async () => {
  try {
    const token = localStorage.getItem('access_token');
    console.log('Token found:', !!token);
    
    if (!token) {
      console.log('No token found');
      return false;
    }

    console.log('Making subscription check request to:', `${process.env.REACT_APP_API_URL}/api/subscription/check/`);
    
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/check/`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    // Log the exact response structure
    console.log('Raw response data:', JSON.stringify(response.data, null, 2));
    
    // Check if the response matches what we expect
    const isSubscribed = response.data.is_active === true && 
                        response.data.renewal_date !== null && 
                        response.data.subscription_status === 'active';
                        
    console.log('Subscription check details:', {
      is_active: response.data.is_active,
      renewal_date: response.data.renewal_date,
      subscription_status: response.data.subscription_status,
      final_result: isSubscribed
    });
    
    return isSubscribed;
    
  } catch (error) {
    console.error('Subscription check error:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    return false;
  }
};