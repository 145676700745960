import React, { useState, useEffect, useMemo } from 'react';
import styles from '../styles/AnalyticsPage.module.css';
import LoadingSpinner from '../components/LoadingSpinner';
import axios from 'axios';
import { calculateProfitLoss } from '../utils/calculateProfitLoss';

const AnalyticsPage = () => {
    const [tips, setTips] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTimeframe, setSelectedTimeframe] = useState('all'); // all, year, 6months, 3months, month
    const [betAmount, setBetAmount] = useState(10);
    const API_URL = process.env.REACT_APP_API_URL;

    const getAuthHeaders = () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            const userStr = localStorage.getItem('user');
            
            console.log('Debug - localStorage:', {
                accessToken: !!accessToken,
                userStr: !!userStr
            });

            if (accessToken) {
                return {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                };
            }

            if (userStr) {
                const user = JSON.parse(userStr);
                if (user?.access) {
                    return {
                        'Authorization': `Bearer ${user.access}`,
                        'Content-Type': 'application/json',
                    };
                }
            }

            console.error('No valid token found in either storage location');
            return null;
        } catch (error) {
            console.error('Error getting auth headers:', error);
            return null;
        }
    };

    useEffect(() => {
        fetchTips();
    }, []);

    const fetchTips = async () => {
        try {
            const headers = getAuthHeaders();
            if (!headers) {
                throw new Error('No valid authentication token found');
            }

            console.log('Fetching tips from:', `${API_URL}/api/tips/`);
            const response = await axios.get(`${API_URL}/api/tips/`, { headers });
            
            // Filter out pending tips
            const completedTips = response.data.filter(tip => tip.result !== 'PENDING');
            setTips(completedTips);
        } catch (err) {
            console.error('Error details:', {
                status: err.response?.status,
                statusText: err.response?.statusText,
                data: err.response?.data,
                message: err.message
            });

            if (err.response?.status === 401) {
                setError('Your session has expired. Please log in again.');
                localStorage.removeItem('user');
                window.location.href = '/login';
            } else {
                setError(err.response?.data?.message || err.message || 'Failed to fetch tips');
            }
        } finally {
            setLoading(false);
        }
    };

    const filterTipsByTimeframe = (tips, timeframe) => {
        const now = new Date();
        const cutoffDate = new Date();
        
        switch(timeframe) {
            case '7days':
                cutoffDate.setDate(now.getDate() - 7);
                break;
            case 'month':
                cutoffDate.setMonth(now.getMonth() - 1);
                break;
            case '3months':
                cutoffDate.setMonth(now.getMonth() - 3);
                break;
            case '6months':
                cutoffDate.setMonth(now.getMonth() - 6);
                break;
            case 'year':
                cutoffDate.setFullYear(now.getFullYear() - 1);
                break;
            default:
                return tips;
        }
        
        return tips.filter(tip => new Date(tip.date) >= cutoffDate);
    };

    const calculateTipTypeStats = (tips, type) => {
        const typeTips = tips.filter(tip => tip.type === type);
        const completedTips = typeTips.filter(tip => tip.result !== 'PENDING');
        
        const wins = completedTips.filter(tip => tip.result === 'WON').length;
        const places = completedTips.filter(tip => tip.result === 'PLACED').length;
        const losses = completedTips.filter(tip => tip.result === 'LOST').length;
        const nrs = completedTips.filter(tip => tip.result === 'NR').length;
        
        // Calculate best winning streak
        let currentStreak = 0;
        let bestStreak = 0;
        
        // Sort tips by date to calculate streak properly
        const sortedTips = [...completedTips].sort((a, b) => new Date(a.date) - new Date(b.date));
        
        sortedTips.forEach(tip => {
            if (tip.result === 'WON') {
                currentStreak++;
                bestStreak = Math.max(bestStreak, currentStreak);
            } else if (tip.result === 'LOST') {
                currentStreak = 0;
            }
            // Ignore PLACED and NR for streak calculations
        });

        const totalBets = completedTips.length - nrs;
        const winRate = totalBets ? ((wins / totalBets) * 100).toFixed(1) : 0;
        
        // Rest of profit calculation remains the same
        let profit = 0;
        completedTips.forEach(tip => {
            if (tip.result === 'WON') {
                profit += (betAmount * Number(tip.odds)) - betAmount;
            } else if (tip.result === 'PLACED' || tip.result === 'LOST') {
                profit -= betAmount;
            }
            // NRs return stake so no profit/loss adjustment needed
        });

        return {
            total: completedTips.length,
            wins,
            places,
            losses,
            winRate,
            profit: profit.toFixed(2),
            bestStreak,
            avgOdds: completedTips.length ? 
                (completedTips.reduce((acc, tip) => acc + Number(tip.odds), 0) / completedTips.length).toFixed(2) : 0
        };
    };

    const calculateEWStats = (tips, betAmount) => {
        const ewTips = tips.filter(tip => tip.type === 'Each Way Pick');
        const completedTips = ewTips.filter(tip => tip.result !== 'PENDING');
        
        if (!completedTips.length) return null;

        const unitStake = betAmount / 2; // Split stake between win and place
        let totalProfit = 0;
        let wins = 0;
        let places = 0;
        let losses = 0;
        let bestPayout = 0;
        
        completedTips.forEach(tip => {
            const odds = Number(tip.odds);
            const placeOdds = (odds - 1) / 4 + 1; // Standard place terms (1/4 odds)
            let thisReturn = 0; // Track return for this individual bet
            
            if (tip.result === 'WON') {
                // Win part: full odds
                const winProfit = (unitStake * odds) - unitStake;
                // Place part: place odds
                const placeProfit = (unitStake * placeOdds) - unitStake;
                thisReturn = winProfit + placeProfit + betAmount; // Add back original stake for total return
                
                totalProfit += (winProfit + placeProfit);
                wins++;
            }
            else if (tip.result === 'PLACED') {
                // Only place part pays out
                const placeProfit = (unitStake * placeOdds) - unitStake;
                thisReturn = placeProfit + (betAmount/2); // Add back place stake for total return
                
                totalProfit += placeProfit - unitStake; // Subtract win stake as it's lost
                places++;
            }
            else if (tip.result === 'LOST') {
                thisReturn = 0;
                totalProfit -= betAmount;
                losses++;
            }
            
            bestPayout = Math.max(bestPayout, thisReturn);
        });

        const totalBets = wins + places + losses;
        const successRate = ((wins + places) / totalBets * 100).toFixed(1);

        return {
            totalBets,
            wins,
            places,
            losses,
            successRate,
            profit: totalProfit.toFixed(2),
            bestPayout: bestPayout.toFixed(2),
            averageStake: betAmount.toFixed(2)
        };
    };

    const stats = useMemo(() => {
        if (!tips.length) return null;
        
        const filteredTips = filterTipsByTimeframe(tips, selectedTimeframe);
        
        // Calculate stats for each type using the shared utility
        const napTips = filteredTips.filter(tip => tip.type === 'NAP');
        const solidTips = filteredTips.filter(tip => tip.type === 'Solid Pick');
        const valueTips = filteredTips.filter(tip => tip.type === 'Value Pick');
        
        const { winOnlyProfit: napProfit } = calculateProfitLoss(napTips);
        const { winOnlyProfit: solidProfit } = calculateProfitLoss(solidTips);
        const { winOnlyProfit: valueProfit } = calculateProfitLoss(valueTips);
        
        return {
            nap: {
                ...calculateTipTypeStats(napTips),
                profit: napProfit.toFixed(2)
            },
            solid: {
                ...calculateTipTypeStats(solidTips),
                profit: solidProfit.toFixed(2)
            },
            value: {
                ...calculateTipTypeStats(valueTips),
                profit: valueProfit.toFixed(2)
            }
        };
    }, [tips, selectedTimeframe, betAmount]);

    const ewStats = useMemo(() => {
        if (!tips.length) return null;
        const filteredTips = filterTipsByTimeframe(tips, selectedTimeframe);
        const ewTips = filteredTips.filter(tip => tip.type === 'Each Way Pick');
        const { eachWayProfit } = calculateProfitLoss(ewTips);
        
        return {
            ...calculateEWStats(ewTips, betAmount),
            profit: eachWayProfit.toFixed(2)
        };
    }, [tips, selectedTimeframe, betAmount]);

    return (
        <div className={styles.analyticsContainer}>
            {loading ? (
                <div className={styles.loadingContainer}>
                    <LoadingSpinner />
                </div>
            ) : error ? (
                <div className={styles.errorMessage}>
                    {error}
                </div>
            ) : (
                <>
                    <div className={styles.header}>
                        <h2>VIP Analytics Dashboard</h2>
                        <div className={styles.controls}>
                            <div className={styles.stakeInput}>
                                <label>Stake Amount (£)</label>
                                <input
                                    type="number"
                                    value={betAmount}
                                    onChange={(e) => setBetAmount(Number(e.target.value))}
                                    min="1"
                                />
                            </div>
                            <select 
                                className={styles.timeframeSelect}
                                value={selectedTimeframe}
                                onChange={(e) => setSelectedTimeframe(e.target.value)}
                            >
                                <option value="7days">Last 7 Days</option>
                                <option value="month">Last Month</option>
                                <option value="3months">Last 3 Months</option>
                                <option value="6months">Last 6 Months</option>
                                <option value="year">Last Year</option>
                                <option value="all">All Time</option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.tipSection}>
                        <h3>NAP Performance</h3>
                        <div className={styles.statsRow}>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.nap.winRate}%</span>
                                <span className={styles.statLabel}>Strike Rate</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={`${styles.statValue} ${Number(stats?.nap.profit) >= 0 ? styles.profit : styles.loss}`}>
                                    {Number(stats?.nap.profit) >= 0 ? '+' : ''}£{stats?.nap.profit}
                                </span>
                                <span className={styles.statLabel}>Profit/Loss to £{betAmount}</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.nap.bestStreak}</span>
                                <span className={styles.statLabel}>Best Streak</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tipSection}>
                        <h3>Solid Picks Performance</h3>
                        <div className={styles.statsRow}>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.solid.winRate}%</span>
                                <span className={styles.statLabel}>Strike Rate</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={`${styles.statValue} ${Number(stats?.solid.profit) >= 0 ? styles.profit : styles.loss}`}>
                                    {Number(stats?.solid.profit) >= 0 ? '+' : ''}£{stats?.solid.profit}
                                </span>
                                <span className={styles.statLabel}>Profit/Loss to £{betAmount}</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.solid.avgOdds}</span>
                                <span className={styles.statLabel}>Average Odds</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tipSection}>
                        <h3>Value Picks Performance</h3>
                        <div className={styles.statsRow}>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.value.winRate}%</span>
                                <span className={styles.statLabel}>Strike Rate</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={`${styles.statValue} ${Number(stats?.value.profit) >= 0 ? styles.profit : styles.loss}`}>
                                    {Number(stats?.value.profit) >= 0 ? '+' : ''}£{stats?.value.profit}
                                </span>
                                <span className={styles.statLabel}>Profit/Loss to £{betAmount}</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{stats?.value.avgOdds}</span>
                                <span className={styles.statLabel}>Average Odds</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tipSection}>
                        <h3>Each Way Performance (£{betAmount/2} E/W)</h3>
                        <div className={styles.statsRow}>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>{ewStats?.successRate}%</span>
                                <span className={styles.statLabel}>Win/Place Rate</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={`${styles.statValue} ${Number(ewStats?.profit) >= 0 ? styles.profit : styles.loss}`}>
                                    {Number(ewStats?.profit) >= 0 ? '+' : ''}£{ewStats?.profit}
                                </span>
                                <span className={styles.statLabel}>Total Profit/Loss</span>
                            </div>
                            <div className={styles.statBox}>
                                <span className={styles.statValue}>£{ewStats?.bestPayout}</span>
                                <span className={styles.statLabel}>Best Return</span>
                            </div>
                        </div>
                        
                        <div className={styles.breakdownRow}>
                            <div className={styles.breakdownItem}>
                                <span className={styles.won}>{ewStats?.wins || 0}</span>
                                <span>Winners</span>
                            </div>
                            <div className={styles.breakdownItem}>
                                <span className={styles.placed}>{ewStats?.places || 0}</span>
                                <span>Places</span>
                            </div>
                            <div className={styles.breakdownItem}>
                                <span className={styles.lost}>{ewStats?.losses || 0}</span>
                                <span>Losses</span>
                            </div>
                            <div className={styles.breakdownItem}>
                                <span>{ewStats?.totalBets || 0}</span>
                                <span>Total Bets</span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default AnalyticsPage;